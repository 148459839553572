.cdk-overlay-pane:has(.drop-down) {
  width: 200px !important;
}
mat-expansion-panel.cp-menu-expansion {
  div.mat-expansion-panel-content {
    div.mat-expansion-panel-body {
      padding: 0px;
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      background-color: #fafafa;
    }
  }
}
