/* You can add global styles to this file, and also import other style files */
// @import "~@angular/material/prebuilt-themes/indigo-pink.css";
:root {
  --radialRed: #ef2637;
  --radialGrey: #cccccc;
  --pagePadding: 1.4rem;
}
html,
body {
  height: 100%;
  margin: 0;
  scroll-behavior: smooth;
}
body.no-scroll {
  overflow: hidden;
}
* {
  font-family: Arial, Helvetica, sans-serif;
}
.content {
  padding: 8px 18px;
  font-family: Arial, Helvetica, sans-serif;
}
.mobile-content {
  padding: 8px 12px;
  font-family: Arial, Helvetica, sans-serif;
}
/* width */
::-webkit-scrollbar {
  width: 7px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(199, 193, 193);
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(173, 169, 169);
}
/* * {
  border: 2px red solid;
} */
.font-bold {
  font-weight: bold;
  margin-bottom: 2px;
}
.no-data-message {
  color: red;
  text-align: center;
  display: block;
  background-color: white;
  font-size: 15px;
  box-shadow: 5px 10px 18px #888888;
  padding: 16px;
  margin-top: -16px;
  font-weight: bold;
}
mat-icon {
  overflow: unset !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
::ng-deep .mat-form-field-label,
.mat-mdc-form-field-hint {
  color: rgba(0, 0, 0, 0.87);
}
.error-snack-bar {
  --mdc-snackbar-container-color: var(--radialRed) !important;
  --mdc-snackbar-supporting-text-color: white !important;
}
.error-snack-bar .mat-mdc-snack-bar-action {
  --mdc-snackbar-supporting-text-color: white !important;
  /**
  below code is added by anshul jain on 21 June 2024 as part of sla graph
  **/
  --mat-snack-bar-button-color: white !important;
}
.success-snack-bar {
  --mdc-snackbar-container-color: green !important;
  --mdc-snackbar-supporting-text-color: white !important;
}
.snack-bar-save {
  --mdc-snackbar-container-color: green !important;
  --mdc-snackbar-supporting-text-color: white !important;
}
.success-snack-bar .mat-mdc-snack-bar-action {
  --mdc-snackbar-supporting-text-color: white !important;
}
.bg-red {
  width: 30vw;
  display: flex;
  margin: auto;
  flex-direction: column;
}
mat-card {
  padding: 16px;
}
.mdc-card__actions .mat-mdc-raised-button {
  margin-right: 8px;
}
#sideNavItems .mdc-list-item__content .mdc-list-item__primary-text {
  display: flex;
  font-weight: bold;
}
.mat-mdc-form-field:not(.no-top-margin) {
  margin-top: 5px;
}
.mat-mdc-button .mdc-button__label {
  margin: 3px;
}
#ob-details-tab .mat-mdc-tab.mdc-tab {
  flex-grow: 0 !important;
}
mat-label {
  color: #000000de !important;
}
a.cp-router.active {
  color: #ef2637;
  border: 3px solid #ef2637;
}
.mat-mdc-card-title {
  font-size: 24px !important;
}
mat-label {
  font-size: 16px;
  font-weight: 400;
}
.mdc-list-item--with-leading-checkbox .mdc-list-item__start {
  margin-right: 8px !important;
}
.mat-mdc-checkbox .mdc-form-field {
  font-size: 16px !important;
}
.mat-mdc-list-base .mdc-list-item__content {
  font-size: 16px;
}
.mat-mdc-radio-button .mdc-form-field {
  font-size: 16px !important;
  margin-bottom: 5px !important;
}
#roleSelection .mdc-form-field > label {
  margin-bottom: 5px !important;
}
.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
  .mdc-list-item__primary-text {
  font-weight: 700 !important;
}
.mat-mdc-option {
  font-size: 14px !important;
  line-height: 3em !important;
}
.mdc-card__actions {
  padding: 0px !important;
}
.mat-mdc-tooltip {
  --mdc-plain-tooltip-supporting-text-size: 14px !important;
}
.mat-mdc-tooltip .mdc-tooltip__surface {
  background-color: gray !important;
  max-width: 275px !important;
  padding: 8px !important;
}
.mat-mdc-option .mdc-list-item__primary-text {
  line-height: 1.5 !important;
}
mat-form-field.cp-mat-options-fit-content {
  .mat-mdc-select-placeholder {
    min-width: 180px !important;
  }
  .mat-mdc-form-field-infix {
    width: fit-content !important;
    min-width: fit-content !important;
  }
  .mat-mdc-form-field {
    width: auto !important;
  }
  .mat-mdc-select-value {
    max-width: 100% !important;
    width: auto !important;
    font-weight: bold;
    margin-right: 9px !important;
  }
}
div.cp-user-info-menu {
  max-width: unset;
}
.no-user-select {
  user-select: none;
}
mat-card.cp-modules-list-card div.mat-expansion-panel-body {
  padding-bottom: 0px;
  padding-left: 0px;
}
// .mat-drawer-content {
//   height: auto !important;
// }
@import url("src/app/shared/layout/header/scss/header-cdk-panel.scss");
.mat-sort-header-container {
  display: flex;
  justify-content: center;
}
.cp-clickable {
  text-decoration: underline;
  text-underline-offset: 4px;
}
.cp-page-padding {
  padding: var(--pagePadding);
}
