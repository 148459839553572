@use "@angular/material" as mat;
$my-primary: mat.define-palette(mat.$red-palette, 600);
$my-accent: mat.define-palette(mat.$red-palette), 600;
// The "warn" palette is optional and defaults to red if not specified.
$red-warn: mat.define-palette(mat.$red-palette, 600);
$my-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-primary,
      warn: $my-primary,
    ),
    typography:
      mat.define-typography-config(
        $font-family: "Arial, Helvetica, sans-serif",
      ),
  )
);
@include mat.all-component-themes($my-theme);
// for z elavtion
.mat-mdc-card.mat-elevation-z6 {
  @include mat.elevation(6);
}
.mat-mdc-card.mat-elevation-z8 {
  @include mat.elevation(8);
}
.mat-mdc-card.mat-elevation-z10 {
  @include mat.elevation(10);
}
.mat-mdc-card.mat-elevation-z11 {
  @include mat.elevation(11);
}
.mat-mdc-card.mat-elevation-z5 {
  @include mat.elevation(5);
}
.mat-mdc-card.mat-elevation-z9 {
  @include mat.elevation(9);
}
.mat-mdc-card.mat-elevation-z1 {
  @include mat.elevation(1);
}
@include mat.all-component-densities(-3);
@include mat.button-density(maximum);
.kpi-sla-form-field-density {
  @include mat.form-field-density(-3);
}
.app-wh-form-field-density {
  @include mat.form-field-density(-3);
}
